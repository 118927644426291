import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../views/HomePage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage
  },
  // {
  //   path: "/gift-details",
  //   name: "GiftDetails",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/GiftDetails.vue")
  // },
  {
    path: "/explore-gifts",
    name: "ExploreGifts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ExploreGifts.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
