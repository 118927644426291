<template>
  <div class="home">
    <div class="home__header">
      <div class="header__top">
        <div>
          <a href="/">
            <img :src="require(`@/assets/images/eden-logo.svg`)" alt="" />
          </a>
        </div>
        <a
          href="https://ouredenlife.com/"
          target="_blank"
          class="header__top-link"
          ><span>visit ouredenlife.com</span>
          <img :src="require(`@/assets/images/eden-link.svg`)" alt="" />
        </a>
      </div>
      <div class="header">
        <header class="hero">
          <div class="hero__title">
            <h1>
              I love you no be <br />by mouth alone <span>&#128154;</span>
            </h1>
            <p>
              Our selection of 50+ cool, creative, and fun gifting ideas has
              something for everyone and all budgets.
            </p>
            <div class="hero__share">
              <span class="hero__share-text">Share with friends</span>
              <div class="hero__share-img">
                <ShareNetwork
                  network="twitter"
                  url="https://gifts.ouredenlife.com"
                  title=""
                  description=""
                  hashtags="GiftWithEden,ILoveYouNoBeByMouthAlone💚"
                >
                  <img
                    :src="require(`@/assets/images/twitter-share.svg`)"
                    alt="twitter"
                  />
                </ShareNetwork>
              </div>
              <div class="hero__share-img">
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https://gifts.ouredenlife.com"
                  target="_blank"
                >
                  <img
                    :src="require(`@/assets/images/facebook-share.svg`)"
                    alt="facebook"
                  />
                </a>
              </div>
              <div class="hero__share-img">
                <ShareNetwork
                  network="whatsapp"
                  url="https://gifts.ouredenlife.com"
                  title=""
                  description=""
                >
                  <img
                    :src="require(`@/assets/images/whatsapp-share.svg`)"
                    alt="whatsapp"
                  />
                </ShareNetwork>
              </div>
            </div>
            <img
              class="arrow"
              src="https://res.cloudinary.com/eden-life-inc/image/upload/v1607163595/gift-sharing/bg1_omsfxk.svg"
              alt=""
            />
          </div>
          <div class="hero__gift">
            <div class="hero__gift-image">
              <img
                src="https://res.cloudinary.com/eden-life-inc/image/upload/v1607163578/gift-sharing/bg2_ebmfwx.svg"
                alt=""
              />
            </div>
          </div>
        </header>
      </div>
    </div>

    <div class="main" id="gift-top">
      <section class="main__gifts">
        <div class="main__gifts-nav">
          <ul class="desktop--nav">
            <li v-for="item in categories" :key="item.id">
              <div
                @click="selectCategory(item)"
                class="category"
                :class="{ active: selected === item.id }"
              >
                <span>{{ item.name }}</span>
                <span class="dot" v-if="item.id === selected"></span>
              </div>
            </li>
          </ul>
          <ul class="mobile__nav">
            <p>Filter</p>
            <div class="mobile__nav-filter">
              <p @click="showFilter = true" ref="toggleCategory">
                {{ selectedCat }}
              </p>

              <div class="mobile__nav-item" v-show="showFilter">
                <li v-for="item in categories" :key="item.id">
                  <div
                    @click="selectCategory(item)"
                    class="category"
                    :class="{ active: selected === item.id }"
                  >
                    <span>{{ item.name }}</span>
                  </div>
                </li>
              </div>
            </div>
          </ul>
        </div>
        <div class="top__gifts" id="load-more">
          <figure
            v-for="item in allGiftsList"
            :key="item.id"
            class="top__gifts-image"
            id="show-modal"
            @click="viewGiftDetails(item)"
          >
            <img :src="item.image_url" :alt="item.name" />
            <figcaption class="top__gifts-name">
              <h6>
                {{ item.name }}
              </h6>
              <p>{{ item.vendor }}</p>
            </figcaption>
          </figure>
        </div>
        <button
          class="btn"
          @click="loadAll()"
          v-if="allGiftsList.length == 12 && selected == 1"
        >
          Load more
        </button>
        <button
          class="btn"
          @click="loadFewGift()"
          v-if="allGiftsList.length > 12 && selected == 1"
        >
          See less
        </button>
      </section>

      <section class="main__carebox">
        <div class="carebox__title">
          <h2>
            Eden’s Carebox
          </h2>
          <p>
            Gift an Eden Carebox to your loved ones. <br />It’s the best way to
            say “i love you.”
          </p>
          <a href="https://carebox.ouredenlife.com" target="_blank"
            >Gift Carebox</a
          >
        </div>
        <div class="carebox__image">
          <img
            src="https://res.cloudinary.com/eden-life-inc/image/upload/v1608102459/gift-sharing/bg3_lrhpdn.svg"
            alt=""
          />
        </div>
      </section>
      <section class="main__ideas">
        <img
          class="bg1"
          src="https://res.cloudinary.com/eden-life-inc/image/upload/v1607163649/gift-sharing/bg4_xupayi.svg"
          alt=""
        />
        <div class="main__ideas-title">
          <h2>Curated with lots of <span>&#128154;</span></h2>
          <p>Show the people your care about that you truly care about them</p>
          <button class="btn" @click="scrollToGift()">See Gifting Ideas</button>
          <img
            src="https://res.cloudinary.com/eden-life-inc/image/upload/v1607163596/gift-sharing/bg5_rj8cqh.svg"
            alt=""
            class=""
          />
        </div>
        <img
          class="bg2"
          src="https://res.cloudinary.com/eden-life-inc/image/upload/v1607163634/gift-sharing/bg6_kwhjwn.svg"
          alt=""
        />
      </section>
      <footer class="main__footer">
        <div>
          <a href="/">
            <img :src="require(`@/assets/images/eden-logo.svg`)" alt="" />
          </a>
        </div>
        <div>
          <p>Copyright Eden Life Inc. {{ new Date().getFullYear() }}</p>
        </div>
      </footer>
    </div>
    <GiftDetails
      v-if="showModal"
      @close="showModal = false"
      :giftItem="giftDetails"
    />
  </div>
</template>

<script>
import { allGifts, fetchGiftByCategory } from "@/requests/api";

export default {
  name: "HomePage",
  data() {
    return {
      showModal: false,
      selected: 1,
      selectedCat: "All",
      allGiftsList: [],
      giftDetails: {},
      giftByCategory: [],
      showFilter: false,
      categories: [
        { id: 1, name: "All" },
        { id: 4, name: "Fashion" },
        { id: 6, name: "Food & Drinks" },
        { id: 5, name: "Home Decor" },
        { id: 7, name: "Wellness & Beauty" },
        { id: 8, name: "Luxury" },
        { id: 3, name: "Others" }
      ]
    };
  },
  components: {
    GiftDetails: () => import("@/components/GiftDetails.vue")
  },
  mounted() {
    let self = this;
    window.addEventListener("keydown", function(event) {
      if (event.key === "Escape") {
        self.showModal = false;
      }
    });
    this.loadAllGifts();
    document.addEventListener("click", this.toggleFilter);
  },

  destroyed() {
    document.removeEventListener("click", this.toggleFilter);
  },
  methods: {
    async selectCategory(item) {
      this.selectedCat = item.name;
      this.showFilter = false;
      this.selected = item.id;
      if (this.selected == 1) {
        this.loadAllGifts();
        return;
      }
      const giftResponse = await fetchGiftByCategory(this.selected);
      this.allGiftsList = giftResponse.all_gifts;
    },
    loadFewGift() {
      this.loadAllGifts();
      const scrollToElement = document.querySelector("#load-more");
      scrollToElement.scrollIntoView({
        behavior: "smooth",
        inline: "nearest"
      });
    },
    async loadAllGifts() {
      const giftResponse = await allGifts();
      this.allGiftsList =
        giftResponse.length > 12 ? giftResponse.slice(0, 12) : giftResponse;
    },
    async loadAll() {
      this.allGiftsList = await allGifts();
    },
    viewGiftDetails(item) {
      this.giftDetails = item;
      this.showModal = true;
    },
    scrollToGift() {
      const scrollToElement = document.querySelector("#gift-top");
      scrollToElement.scrollIntoView({
        behavior: "smooth",
        inline: "nearest"
      });
    },
    toggleFilter(event) {
      let elA = this.$refs.toggleCategory;
      if (!elA.contains(event.target)) {
        this.showFilter = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/_homepage";
</style>
