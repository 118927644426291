import { fetchWrapper } from "./base";

export const allGifts = () => {
  return fetchWrapper(`all-gifts`).get();
};

export const giftDetails = id => {
  return fetchWrapper(`all-gifts/${id}`).get();
};

export const fetchGiftByCategory = id => {
  return fetchWrapper(`categories/${id}`).get();
};
